import {createRouter, createMemoryHistory} from 'vue-router'
import MainMenu from '@/views/MainMenu'

const routes = [
  {
    path: '/',
    name: 'MainMenu',
    component: MainMenu
  },
  {
    path: '/lobby/:matchID',
    name: 'Lobby',
    props: true,
    component: () => import('../views/Lobby.vue')
  },
  {
    path: '/game/:matchID',
    name: 'Game',
    props: true,
    component: () => import('../views/Game.vue')
  }
]

const router = createRouter({
  history: createMemoryHistory(process.env.BASE_URL),
  routes
})

export default router
