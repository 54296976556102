<template>
  <button>
    <slot/>
  </button>
</template>

<script>
export default {
  name: "Button"
}
</script>

<style scoped>
button:focus, button:hover {
  outline: none;
  border: 2px solid black;
}

button {
  min-width: 10rem;
  font-weight: bold;
  border: 2px solid transparent;
  font-size: 1.2em;
  background: none;
}
</style>
