import {INVALID_MOVE, ActivePlayers} from "boardgame.io/core";

const numbers = [
    '2', '3', '4', '5',
    '6', '7', '8', '9', '10',
    'J', 'Q', 'K', 'A'
]

// const types = [
//     'Clubs', 'Diamonds',
//     'Hearts', 'Spades'
// ]

const types = [
    '♠', '♥', '♦', '♣'
]

function getCards() {
    let cards = []
    let i = 0
    for (const n of numbers) {
        for (const t of types) {
            cards.push({type: t, num: n, index: i++})
        }
    }
    Object.freeze(cards)
    return cards
}

export const Cards = getCards()
console.assert(Cards.length === 52)

function newOrderedDeck() {
    return [...Array(52).keys()]
}

function draw(deck, N) {
    return deck.splice(deck.length - N, N)
}

function getTopOpenCard(G) {
    let i = G.open.length - 1
    while (Cards[G.open[i]].num === '3' && i > 0) {
        i--
    }
    return G.open[i]
}

function areAllSameNum(cardsIndexes) {
    if (cardsIndexes.length === 1) return true
    let firstNum = Cards[cardsIndexes[0]].num
    for (let i = 1; i < cardsIndexes.length; i++) {
        if (Cards[cardsIndexes[i]].num !== firstNum) {
            return false
        }
    }
    return true
}

function canBePutOn(below, above) {
    let bcn = Cards[below].num
    let acn = Cards[above].num
    if (['2', '3', '10'].includes(acn)) return true
    if (['2', '10'].includes(bcn)) return true
    let bci = numbers.indexOf(bcn)
    let aci = numbers.indexOf(acn)
    if (bcn === '7') {
        return bci >= aci
    } else {
        return bci <= aci
    }
}

export const EightAndAHalf = {
    name: 'default',
    setup: ctx => {
        let deck = ctx.random.Shuffle(newOrderedDeck())
        let players = {}
        ctx.playOrder.forEach(p => {
            players[p] = {
                hand: draw(deck, 6),
                open: [],
                hidden: draw(deck, 3)
            }
        })
        return {deck, players, open: [], burned: [], leftInDeck: deck.length}
    },
    playerView: (G, ctx, playerID) => {
        let r = {...G}
        delete r.deck
        r.players = {}
        ctx.playOrder.forEach(p => {
            r.players[p] = {
                open: G.players[p].open
            }
            if (playerID === p) {
                r.players[p].hand = G.players[p].hand
            }
        })
        return r
    },
    phases: {
        chooseOpen: {
            start: true,
            turn: {
                activePlayers: ActivePlayers.ALL_ONCE,
                onMove: (G, ctx) => {
                    if (!ctx.activePlayers) ctx.events.endPhase()
                }
            },
            moves: {
                openCards: (G, ctx, cardsIndexes) => {
                    let player = G.players[ctx.playerID]
                    if (cardsIndexes.length !== 3) return INVALID_MOVE
                    if (!cardsIndexes.every(v => player.hand.includes(v))) return INVALID_MOVE
                    player.hand = player.hand.filter(v => !cardsIndexes.includes(v))
                    player.open = cardsIndexes
                }
            },
            onEnd: G => {
                G.open.push(G.deck.pop())
                G.leftInDeck--
            },
            next: 'play',
        },
        play: {
            turn: {
                onBegin: (G, ctx) => {
                    let player = G.players[ctx.currentPlayer]
                    let lastCard = getTopOpenCard(G)
                    if (!player.hand.some(v => canBePutOn(lastCard, v))) {
                        let remain = G.open.pop()
                        player.hand.push(...G.open)
                        G.open = [remain]
                        ctx.events.endTurn()
                    }
                }
            },
            moves: {
                putCards: {
                    move: (G, ctx, cardsIndexes) => {
                        let player = G.players[ctx.playerID]
                        if (cardsIndexes.length === 0) return INVALID_MOVE
                        if (!cardsIndexes.every(v => player.hand.includes(v))) return INVALID_MOVE
                        if (!canBePutOn(getTopOpenCard(G), cardsIndexes[0])) return INVALID_MOVE
                        if (!areAllSameNum(cardsIndexes)) return INVALID_MOVE

                        cardsIndexes.forEach(v => G.open.push(v))
                        player.hand = player.hand.filter(v => !cardsIndexes.includes(v))
                        const num = Cards[cardsIndexes[0]].num
                        if (num === '10') {
                            let remain = G.open.pop()
                            G.burned.push(...G.open)
                            G.open = [remain]
                        }
                        if (player.hand.length < 3 && G.deck.length > 0) {
                            player.hand.push(...draw(G.deck, Math.min(3 - player.hand.length, G.deck.length)))
                            G.leftInDeck = G.deck.length
                        }
                        if (player.hand.length === 0 && G.deck.length === 0) {
                            if (player.open.length > 0) {
                                player.hand = player.open
                                player.open = []
                            } else {
                                player.hand = player.hidden
                            }
                        }
                        if (num !== '8') ctx.events.endTurn()
                    }, client: false
                }
            }
        },
        endIf: (G, ctx) => {
            let player = G.players[ctx.currentPlayer]
            if (player.hand.length === 0 &&
                player.open.length === 0 &&
                player.hidden.length === 0)
                return {winner: ctx.playerID}
        }
    }
}
