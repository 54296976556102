<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "UICard"
}
</script>

<style scoped>
.card {
  background: white;
  padding: 1em 0.1em 0.2em 0.1em;
  border: 4px solid black;
  width: 17.5rem;
  min-height: 24.5rem;
  font-size: 24px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 20px 20px #AAA;
}


:slotted(input) {
  width: 10rem;
  font-weight: bold;
  font-size: 25px;
  background: none;
  border: 2px solid transparent;
}
</style>
