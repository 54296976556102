import {LobbyClient, Client} from 'boardgame.io/client'
import {EightAndAHalf} from '@/Game/core'
import {SocketIO} from "boardgame.io/multiplayer"

const serverPath = window.location.origin

const gameID = 'default'
const numPlayers = 2
const lobbyClient = new LobbyClient({server: serverPath})


export function createNewClient(matchID, playerID, auth) {
    const c = Client({
        game: EightAndAHalf,
        numPlayers,
        multiplayer: SocketIO({server: serverPath}),
        matchID,
        playerID,
        credentials: auth,
        debug: false
    })
    c.start()
    return c
}

const createGame = async () => {
    const {matchID} = await lobbyClient.createMatch(gameID, {
        numPlayers: numPlayers,
        unlisted: true
    })
    return matchID
}

const getJoinedNum = async (matchID) => {
    const {players} = await lobbyClient.getMatch(gameID, matchID)
    return players.filter(p => p.name).length
}

const joinGame = async (matchID) => {
    let i = 0
    while (i < numPlayers) {
        try {
            const playerID = '' + i
            const {playerCredentials} = await lobbyClient.joinMatch('default',
                matchID, {
                    playerID, playerName: playerID
                })
            return {playerID, auth: playerCredentials}
        } catch (err) {
            i++
        }
    }
    throw Error("")
}

const leaveGame = async (matchID, playerID, auth) => {
    await lobbyClient.leaveMatch(gameID, matchID, {
        playerID, credentials: auth
    })
}

export default {
    createGame,
    createNewClient,
    joinGame,
    getJoinedNum,
    leaveGame
}
//export {lobbyClient, createNewClient}
