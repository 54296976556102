<template>
  <router-view/>
</template>

<style>
#app {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  /*background: linear-gradient(118deg,*/
  /*white 0 44%,*/
  /*#FE0000 44% 46%,*/
  /*#FD8C00 46% 48%,*/
  /*#FFE500 48% 50%,*/
  /*#119F0B 50% 52%,*/
  /*#0644B3 52% 54%,*/
  /*#C22EDC 54% 56%,*/
  /*white 56% 100%);*/
}

* {
  font-family: "Courier New", monospace;
  text-align: center;
  outline: none;
  margin: 0;
  box-sizing: border-box;
}
</style>
