<template>
  <UICard>
    <h1>Cards IO</h1>
    <input v-model="gameCodeInput" placeholder="Game Code"/>
    <div class="container">
      <Button v-on:click="joinExistingGame">Join</Button>
      <div>
        <hr/>
        OR
        <hr/>
      </div>
      <Button v-on:click="createNewGame">Create</Button>
    </div>
  </UICard>
</template>

<script>
import LCM from '@/Game/client'
import UICard from '@/components/UICard'
import Button from '@/components/Button'

export default {
  name: "MainMenu",
  components: {
    Button,
    UICard
  },
  data() {
    return {
      gameCodeInput: "",
      credentials: ""
    }
  },
  methods: {
    async createNewGame() {
      const matchID = await LCM.createGame()
      await this.$router.push({
        name: 'Lobby',
        params: {matchID: matchID}
      })
    },
    async joinExistingGame() {
      await this.$router.push({
        name: 'Lobby', params: {matchID: this.gameCodeInput}
      })
    }
  }
}
</script>

<style scoped>
.container {
  margin-bottom: 2em;
  justify-content: space-between;
  height: 140px;
  display: flex;
  flex-direction: column
}
</style>
